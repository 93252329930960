import InViewportController from './InViewportController';
import ScrollController from "./ScrollController";
const inViewportCtrl = new InViewportController();

function onLoad(callback) {
    if (document.readyState !== 'loading') {
        callback.call(this);
    } else {
        document.addEventListener('DOMContentLoaded', callback);
    }
}

const tim = () => {
    console.log("tim");
    window.scrollTo({
        top: window.innerHeight,
        left: 0,
        behavior: 'smooth'
    });
}

onLoad(() => {
    inViewportCtrl.init();
    new ScrollController(document.querySelector("header video"));
    //setTimeout(tim,3400);
});

//setTimeout(tim,1500);
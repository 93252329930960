// @ts-ignore
import Rellax from "rellax"

export default class ScrollController
{
	headerBackground: HTMLElement;
	min = 0.1;
	max = 0.5;

	constructor(headerBackground: HTMLElement) {
		this.headerBackground = headerBackground;
		window.addEventListener("scroll",this.handleScroll);
	}

	handleScroll=(e: Event)=> {
		this.handleHeaderBackground();
	};

	handleHeaderBackground=()=> {
		const max = this.max * 100;
		//const rect = this.headerBackground.getBoundingClientRect();
		/*console.log(window.scrollY, window.innerHeight,window.scrollY > window.innerHeight);
		if (window.scrollY > window.innerHeight) {*/
		let opacity = this.max - (((max / window.innerHeight) * window.scrollY) / max) * 0.85;
			//const opacity = 0.35 - (((100 / window.innerHeight) * window.scrollY) / 100) / 2;
			//const opacity = 1 - ((100 / window.innerHeight) * window.scrollY) * 10;
		if (opacity < this.min) opacity = this.min;
		if (opacity > this.max) opacity = this.max;
		console.log(opacity, opacity < this.min, this.min)
		this.headerBackground.style.opacity = opacity.toString();
			//document.body.style.backgroundPosition = `50% ${100 - opacity * 100}%`;
		//}
	};

	elementInViewport(el) {
		const rect = el.getBoundingClientRect();
		return (
			rect.top >= 0 &&
			rect.left >= 0 &&
			rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
			rect.right <= (window.innerWidth || document.documentElement.clientWidth)
		);
	}
}

